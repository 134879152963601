<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-button-group class="vg_mr_8">
          <el-button :type="activeTableName === 'sh' ? 'primary' : ''" size="small" @click="switchTable('sh')">S H</el-button>
          <el-button :type="activeTableName === 'hk' ? 'primary' : ''" size="small" @click="switchTable('hk')">H K</el-button>
        </el-button-group>
        <el-button type="primary" size="small" @click="addSoll(1)">生成收款单</el-button>
        <el-button type="success" size="small" @click="addSoll(2)" v-show="activeTableName === 'sh'">生成预收款单</el-button>
        <el-date-picker
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          size="small"
          v-model="dateRange"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="收款开始日期"
          end-placeholder="收款结束日期"
        />
        <el-button type="primary" size="small" icon="el-icon-search" @click="getTableData"> 查询</el-button>
        <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="refresh()"> 刷新</el-button>
        <!--        <el-button type="danger" size="small" class="vd_export" icon="el-icon-delete" @click="deleteSgin()"> 删除</el-button>-->
      </div>
      <search-table
        v-if="activeTableName === 'sh'"
        ref="multiTable"
        :data="tableData"
        :tableRowKey="'sgin_id'"
        :columns="shTableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @getTableData="getTableData"
        :show-summary="true"
        :summariesColumns="['segi_total', 'segi_claied', 'segi_unclai']"
      >
      </search-table>
      <search-table
        v-if="activeTableName === 'hk'"
        ref="multiTable"
        :data="tableData"
        :tableRowKey="'hgin_id'"
        :columns="hkTableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        :show-summary="true"
        :summariesColumns="['hegi_total', 'hegi_claied', 'hegi_unclai']"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @getTableData="getTableData"
      >
      </search-table>
    </el-card>
  </div>
</template>

<script>
import { getNoCatch, post, postNoCatch } from '@api/request';
import { segiAPI } from '@api/modules/segi';
import { sollAPI } from '@api/modules/soll';
import pubPagination from '@/components/common/pubPagination';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { hkTableProperties, shTableProperties } from '@/views/FinanceManagement/SginManage/sgin';
import { hegiAPI } from '@api/modules/hegi';
import { hollAPI } from '@api/modules/holl';
import { cloneDeep } from 'lodash';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'SegiList',
  components: {
    SearchTable,
    pubPagination
  },
  data() {
    return {
      activeTableName: 'sh',
      dateRange: [],
      dateRange2: [],
      shTableProperties: shTableProperties,
      hkTableProperties: hkTableProperties,
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      stffUserList: [],
      cpttList: [],
      custOptionList: [],
      deptList: []
    };
  },
  async mounted() {
    this.$refs.multiTable.searchForm.segi_situ = 0;
    this.$refs.multiTable.searchForm.hegi_situ = 0;
    await this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/segi_edit' || from.path === '/hegi_edit') {
        this.initData();
      }
    }
  },
  methods: {
    initData() {
      this.getTableData();
      // this.getDept();
    },
    async getTableData() {
      await (this.loadFlag = true);
      let searchForm = cloneDeep(this.$refs.multiTable.searchForm);
      let [startTime, endTime] = this.dateRange || [];
      searchForm.startTime = startTime;
      searchForm.endTime = endTime;
      let [startTime2, endTime2] = this.dateRange2 || [];
      searchForm.startTime2 = startTime2 ? startTime2 / 1000 : null;
      searchForm.endTime2 = endTime2 ? endTime2 / 1000 : null;
      getNoCatch(this.activeTableName === 'sh' ? segiAPI.getSgins : hegiAPI.getHgins, searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
        setTimeout(() => (this.loadFlag = false), 800);
      });
    },

    // 生成收款单
    addSoll(type) {
      if (this.multiSelection.length > 1) return this.$message.warning('请选择一条数据!');
      let ids = [];
      let activeNameBoolean = this.activeTableName === 'sh';
      if (activeNameBoolean) {
        ids = Array.from(this.multiSelection, ({ segi_id }) => segi_id);
      } else {
        ids = Array.from(this.multiSelection, ({ hegi_id }) => hegi_id);
      }
      let postBody = [
        { soll_ids: ids, soll_status: type },
        { holl_ids: ids, holl_status: 1 }
      ];
      postNoCatch(activeNameBoolean ? sollAPI.addSoll : hollAPI.addHoll, postBody[activeNameBoolean ? 0 : 1]).then(({ data }) => {
        this.$refs.multiTable.clearSelection();
        this.$message.success('生成成功');
        if (data.soll_ids) {
          data.soll_ids.forEach(async item => {
            await this.$router.push({
              name: 'SollEdit',
              query: {
                key: UrlEncode.encode(
                  JSON.stringify({
                    perm_id: 144,
                    form_id: item
                  })
                ),
                t: Date.now()
              }
            });
          });
        }
        if (data.holl_ids) {
          data.holl_ids.forEach(
            async item =>
              await this.$router.push({
                name: 'HollEdit',
                query: {
                  key: UrlEncode.encode(
                    JSON.stringify({
                      perm_id: 145,
                      form_id: item
                    })
                  )
                }
              })
          );
        }
      });
    },
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    refresh() {
      this.dateRange = [];
      this.dateRange2 = [];
      this.$refs.multiTable.resetFields();
      this.initData();
    },
    //删除收款认领清单
    deleteSgin() {
      if (this.multiSelection.length === 0) return this.$message.warning('请至少选择一条数据!');
      if (this.multiSelection.length > 10) return this.$message.warning('最多选择十条数据!');
      // let segiIds = [];
      // let activeNameBoolean = this.activeTableName === 'sh';
      // if (activeNameBoolean) {
      //   segiIds = Array.from(this.multiSelection, ({ segi_id }) => segi_id);
      // } else {
      //   segiIds = Array.from(this.multiSelection, ({ hegi_id }) => hegi_id);
      // }
      let activeNameBoolean = this.activeTableName === 'sh';
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          post(activeNameBoolean ? segiAPI.deleteSgins : hegiAPI.deleteHoll, this.multiSelection)
            .then(res => {
              if (res.data.code === 0) {
                this.$message({ message: res.data.msg, type: 'success' });
                this.refresh();
              } else {
                this.$message({ message: res.data.msg, type: 'warning' });
              }
            })
            .catch(res => {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },
    async switchTable(pageName) {
      await (this.activeTableName = pageName);
      // await this.$refs.multiTable.resetFields();
      await this.getTableData();
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
