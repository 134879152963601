import topic from '../topic';
export const segiAPI = {
  getSegis: topic.segiTopic + '/get_segis',
  getSgins: topic.segiTopic + '/get_sgins',
  getSegiById: topic.segiTopic + '/get_segi_by_id',
  addSegi: topic.segiTopic + '/add_segi',
  editSegi: topic.segiTopic + '/edit_segi',
  deleteSegiByIds: topic.segiTopic + '/delete_segi_by_ids',
  deleteSgins: topic.segiTopic + '/delete_sgins'
};
