import store from '@/store';
import { getDateNoTime } from '@assets/js/dateUtils';

export const shTableProperties = [
  store.state.selection,
  store.state.index,
  { label: '登记单号', prop: 'segi_no', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '汇款客户', prop: 'cust_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  {
    label: '收款日期',
    prop: 'segi_date',
    itemType: 'date',
    valueFormat: 'timestamp',
    input: true,
    sortable: false,
    widthAuto: true,
    formatter: val => getDateNoTime(val, false)
  },
  {
    label: '收款单状态',
    prop: 'segi_situ',
    itemType: 'select',
    options: [
      { value: 0, label: '未生成' },
      { value: 1, label: '已生成收款单' },
      { value: 2, label: '已生成预收单' }
    ],
    input: true,
    sortable: false,
    widthAuto: true,
    formatter: val => formatSitu(val)
  },
  { label: '收款金额', prop: 'segi_total', itemType: 'input', input: false, sortable: false, widthAuto: true, align: 'right' },
  { label: '币种', prop: 'cust_currency', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  { label: '已领用金额', prop: 'segi_claied', itemType: 'input', input: false, sortable: false, widthAuto: true, align: 'right' },
  { label: '未领用金额', prop: 'segi_unclai', itemType: 'input', input: false, sortable: false, widthAuto: true, align: 'right' },
  { label: '收款银行', prop: 'cptt_bank_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '收款抬头', prop: 'cptt_aname', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '部门', prop: 'dept_name', itemType: 'input', input: true, sortable: false, widthAuto: true }
];
export const hkTableProperties = [
  store.state.selection,
  store.state.index,
  { label: '登记单号', prop: 'hegi_no', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '汇款客户', prop: 'cust_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  {
    label: '收款日期',
    prop: 'hegi_date',
    itemType: 'date',
    valueFormat: 'timestamp',
    input: true,
    sortable: false,
    widthAuto: true,
    formatter: val => getDateNoTime(val, false)
  },
  {
    label: '收款单状态',
    prop: 'hegi_situ',
    itemType: 'select',
    options: [
      { value: 0, label: '未生成' },
      { value: 1, label: '已生成收款单' },
      { value: 2, label: '已生成预收单' }
    ],
    input: true,
    sortable: false,
    widthAuto: true,
    formatter: val => formatSitu(val)
  },
  { label: '收款金额', prop: 'hegi_total', itemType: 'input', input: false, sortable: false, widthAuto: true, align: 'right' },
  { label: '币种', prop: 'cust_currency', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  { label: '已领用金额', prop: 'hegi_claied', itemType: 'input', input: false, sortable: false, widthAuto: true, align: 'right' },
  { label: '未领用金额', prop: 'hegi_unclai', itemType: 'input', input: false, sortable: false, widthAuto: true, align: 'right' },
  { label: '收款银行', prop: 'cptt_bank_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '收款抬头', prop: 'cptt_aname', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '部门', prop: 'dept_name', itemType: 'input', input: true, sortable: false, labelWidth: '120px' }
];
const formatSitu = val => {
  switch (val) {
    case 0:
      return '未生成';
    case 1:
      return '已生成收款单';
    case 2:
      return '已生成预收单';
  }
};
